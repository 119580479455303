<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="300px"  
  >
    <v-card>
      <v-card-title class="justify-center"> 
          <div>
            {{ item.periodo }}
          </div>
      </v-card-title>   
      <v-divider></v-divider> 
      <v-card-text class="mt-4">
          <v-row>
              <v-col>
                 <p>Capital</p>             
              </v-col>
              <v-col>
               <p class="text-right font-weight-bold">$ {{ $formatNumber(item.capital) }}</p> 
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                 <p>Recargo</p>             
              </v-col>
              <v-col>
               <p class="text-right font-weight-bold">$ {{ $formatNumber(item.recargo) }}</p> 
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                 <p>Multa</p>             
              </v-col>
              <v-col>
               <p class="text-right font-weight-bold">$ {{ $formatNumber(item.multa) }}</p> 
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                 <p>Total</p>             
              </v-col>
              <v-col>
                <p class="text-right font-weight-bold">$ {{ $formatNumber(item.total) }}</p> 
              </v-col>
          </v-row>                              
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="blue darken-1"
          text
          @click.stop="show=false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

  export default {
    data () {
      return {
        item: '',       
      }
    },
    props: {
      value: Boolean,
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    methods: {
      setForm: function (item) {
        this.item = item
      },
    },
  }
</script>